import { User } from "./User";
import { Category } from "./Category";
import { LineOfBusiness } from "./LineOfBusiness";
export interface Claim {
  ID: number;
  SID: string;
  CreationTimestamp: number;
  ExpirationTimestamp: number;
  SubmissionTimestamp: number;
  AcknowledgementTimestamp: number;
  FundStartTimestamp?: number;
  Category: Category;
  LineOfBusiness: LineOfBusiness;
  Status: ClaimStatus;
  CreationUser: User;
}

export enum ClaimStatus {
  CREATED = 1,
  SUBMITTED = 2,
  ACKNOWLEDGED = 3
}

export type ClaimConfirmation = Omit<
  Claim,
  | "ID"
  | "Category"
  | "Status"
  | "CreationUser"
  | "SubmissionTimestamp"
  | "AcknowledgementTimestamp"
>;

export interface ClaimRequest {
  ID: number;
  SID: string;
  Description: string;
  Active: boolean;
  Notes: string;
  Type: ClaimRequestType;
  Status: ClaimRequestStatus;
  CreationTimestamp: number;
  ReviewTimestamp: number;
  CompletionTimestamp: number;
  ClaimRequestCreationUser: User;
  ClaimRequestReviewUser: User;
  ClaimRequestCompletionUser: User;
  Claim: Claim;
}

export enum ClaimRequestType {
  SURVIVOR_ESCALATION_REQUEST = 1,
  BROADSPIRE_ESCALATION_REQUEST = 2
}

export enum ClaimRequestStatus {
  PENDING = 1,
  APPROVED = 2,
  REJECTED = 3,
  COMPLETED = 4
}

export const CLAIM_ACTION_TYPE = {
  OPEN_NEW_CLAIM_MODAL: "OPEN_NEW_CLAIM_MODAL",
  CLOSE_NEW_CLAIM_MODAL: "CLOSE_NEW_CLAIM_MODAL",
  OPEN_CLAIM_MODAL: "OPEN_CLAIM_MODAL",
  CLOSE_CLAIM_MODAL: "CLOSE_CLAIM_MODAL",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  DISABLE: "DISABLE",
  FILTER_CLAIMS: "FILTER_CLAIMS"
};
