














import { Component, Vue } from "vue-property-decorator";
import Navigation from "@views/components/Navigation.vue";
import ClaimProcessingForm from "@views/components/ClaimProcessingForm.vue";
@Component({
  components: {
    Navigation,
    ClaimProcessingForm
  }
})
export default class Home extends Vue {}
